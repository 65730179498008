import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
    IActivationFormStepOneValues,
    IActivationFormStepThreeValues,
    IActivationFormStepTwoValues,
    IActivationMutation,
} from '../../models/activation.model';
import { RootState } from '../store';
import { activationApi } from '../api/activation';

export interface INewActivationState {
    mutation: IActivationMutation;
}

const initialState: INewActivationState = {
    mutation: {
        activationId: null,
        step: 1,
        productsCount: 0,
        packagesCount: 0,
        packageId: '',
        cost: '',
        totalCost: '',
        type: '',
        templateId: '',
        chiefReview: '',
        name: '',
        displayName: '',
        ownerEmail: '',
        focus: '',
        comment: '',
        isTaxableReward: '',
        customGroup: '',
        divisionIds: [],
        brandIds: [],
        clubIds: [],
        tasks: [],
        products: [],
        rewards: [],
        includedProducts: '',
        submitForVerification: false,
        mode: 'new',
    },
};

export const newActivationSlice = createSlice({
    name: 'newActivation',
    initialState,
    reducers: {
        setNewActivationStepOneValues: (
            state,
            {
                payload,
            }: PayloadAction<
                IActivationFormStepOneValues & {
                    activationId: number | null | undefined;
                    step: number;
                }
            >
        ) => {
            state.mutation = { ...state.mutation, ...payload };

            if (payload.type === 'seed') {
                state.mutation.rewards = [];
                state.mutation.isTaxableReward = false;
            } else if (payload.type === 'no-seed') {
                state.mutation.products = [];
                state.mutation.cost = '';
                state.mutation.totalCost = '';
                state.mutation.packageId = '';
            }
        },
        setNewActivationStepTwoValues: (
            state,
            {
                payload,
            }: PayloadAction<
                IActivationFormStepTwoValues & {
                    activationId: number | null | undefined;
                    step: number;
                }
            >
        ) => {
            state.mutation = { ...state.mutation, ...payload };

            if (!payload.isTaxableReward) {
                state.mutation.rewards = [];
            }
        },
        setNewActivationStepThreeValues: (
            state,
            {
                payload,
            }: PayloadAction<
                IActivationFormStepThreeValues & {
                    activationId: number | null | undefined;
                    step: number;
                }
            >
        ) => {
            state.mutation = { ...state.mutation, ...payload };
        },
        setNewActivationValues: (state, { payload }: PayloadAction<IActivationMutation>) => {
            state.mutation = { ...state.mutation, ...payload };
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                activationApi.endpoints.saveActivation.matchFulfilled,
                activationApi.endpoints.updateActivation.matchFulfilled
            ),
            (state, action) => {
                const { payload } = action;
                state.mutation.activationId = payload.activationId;

                if (!payload.availableActions.edit) {
                    state.mutation = initialState.mutation;
                }
            }
        );
    },
});

export default newActivationSlice.reducer;

export const {
    setNewActivationStepOneValues,
    setNewActivationStepTwoValues,
    setNewActivationStepThreeValues,
    setNewActivationValues,
} = newActivationSlice.actions;

export const selectActivationState = (state: RootState) => state.activation;
