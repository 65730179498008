import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    titleText,
    list,
    ratio,
    imageLink,
    image,
    button,
} from './instagram-eight.module.scss';
import { ISection } from '../../models/section.model';
import { IInstagramPost } from '../../models/instagram-post.model';
import { dataLayerEvents } from '../../config/data-layer';

import Section from '../hoc/section';
import Content from '../atoms/content';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

export type IInstagram = ISection & {
    content: {
        images: IInstagramPost[];
    };
};

interface IInstagramEightProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IInstagram;
}

const InstagramEight: React.FC<IInstagramEightProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const {
        sectionId,
        css,
        style,
        content: { images, texts },
    } = section;

    const [title, url, buttonText] = texts;

    const handleClick = () => {
        dataLayerEvents.socialClick('Instagram', 'share', `${url}`);
    };

    if (!images || images.length === 0) return null;

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            {title && (
                <Content className={titleText}>
                    <Markdown>{title}</Markdown>
                </Content>
            )}
            <ul className={list}>
                {images.map((instagramPost, index) => {
                    return (
                        <li key={`instagram-post-${index}`} onClick={handleClick}>
                            <a
                                className={imageLink}
                                href={instagramPost.url}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <Image
                                    className={image}
                                    ratioClass={ratio}
                                    media={instagramPost.media}
                                />
                            </a>
                        </li>
                    );
                })}
            </ul>
            <span onClick={handleClick} className={button}>
                <Button stylePreset={'instagram-cta'} as={'link'} to={url || '#'} target={'_blank'}>
                    <Markdown useDefaultStyles={false}>{buttonText ? buttonText : ''}</Markdown>
                </Button>
            </span>
        </Section>
    );
};

export default InstagramEight;
