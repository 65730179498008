import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
    IActivationTemplateMutation,
    IActivationTemplateStepOneValues,
    IActivationTemplateTask,
} from '../../models/activation-template.model';

export interface INewActivationTemplateState {
    mutation: IActivationTemplateMutation;
}

const initialState: INewActivationTemplateState = {
    mutation: {
        submitForVerification: false,
        copyStep: 1,
        goal: '',
        copyDisplayName: '',
        tasks: [],
    },
};

export const newActivationTemplateSlice = createSlice({
    name: 'newActivationTemplate',
    initialState,
    reducers: {
        setNewActivationTemplateStepOneValues: (
            state,
            { payload }: PayloadAction<IActivationTemplateStepOneValues>
        ) => {
            state.mutation = { ...state.mutation, ...payload };
        },
        setNewActivationTemplateTasksValues: (
            state,
            { payload }: PayloadAction<{ tasks: IActivationTemplateTask[]; copyStep: number }>
        ) => {
            state.mutation = {
                ...state.mutation,
                tasks: [...payload.tasks],
                copyStep: payload.copyStep,
            };
        },
        setNewActivationTemplateValues: (
            state,
            { payload }: PayloadAction<IActivationTemplateMutation>
        ) => {
            state.mutation = { ...state.mutation, ...payload };
        },
    },
});

export default newActivationTemplateSlice.reducer;

export const {
    setNewActivationTemplateStepOneValues,
    setNewActivationTemplateTasksValues,
    setNewActivationTemplateValues,
} = newActivationTemplateSlice.actions;

export const selectActivationTemplateState = (state: RootState) => state.activationTemplate;
