import React from 'react';
import { useQuery } from '@tanstack/react-query';

import {
    regulationsHeader,
    regulationsContent,
    companyName,
    loader,
    sectionBox,
} from './rule.module.scss';

import { ISection } from '../../models/section.model';
import { getInstagramRegisterRules } from '../../api-clients/instagram-register';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import Markdown from '../hoc/markdown';

export interface IRulesProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function Rule({ className, section, TitleTag }: IRulesProps) {
    const { sectionId, css, style } = section;

    const t = useTranslations('SignUp');

    const { data, isLoading, isSuccess, isError } = useQuery({
        queryKey: ['instagramRegisterRules'],
        queryFn: getInstagramRegisterRules,
    });

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionBox}`}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={regulationsHeader}>
                {t.rules} <span className={companyName}>{t.companyName}</span>
            </div>
            {isLoading && <Loader className={loader} />}
            {isSuccess && data && (
                <Markdown className={regulationsContent}>{data.content}</Markdown>
            )}
            {isError && <p>{t.error.rules}</p>}
        </Section>
    );
}
